import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../../../core/services/dialog.service';
import { ParentAuthenticationDialog } from '../../parent-authentication-dialog';
import { BreakpointService } from '../../../../../core/services/breakpoint.service';
import { SignUpFormComponent } from '../form/sign-up-form.component';

/**
 * Component for sign up dialog
 */
@Component({
  selector: 'app-signup',
  templateUrl: './sign-up.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent
  extends ParentAuthenticationDialog
  implements OnInit
{
  @Input() isDialog = true;

  /** Large screen flag */
  ls = false;

  constructor(
    injector: Injector,
    private readonly breakpointService: BreakpointService,
    private readonly changeDetection: ChangeDetectorRef,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(isLargeScreen => {
        this.ls = isLargeScreen;
        this.changeDetection.detectChanges();
      });
  }
}

/**
 * Signup link, opens the SignUp dialog.
 */
@Component({
  selector: 'app-signup-link',
  template: `
    <a link variant="link1" color="primary" (click)="openSignUp()">
      <ng-content></ng-content>
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpLinkComponent {
  @Input() isDialog = true;

  @Input() isEmailLink = false;

  constructor(
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {}

  openSignUp() {
    if (!this.isDialog) {
      this.router.navigate([this.isEmailLink ? '/sign-up/email' : '/sign-up']);
    } else {
      this.dialogService.clearAndOpen(
        this.isEmailLink ? SignUpFormComponent : SignUpComponent,
      );
    }
  }
}
