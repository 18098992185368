<dialog-wrapper width="sm" [position]="ls ? 'center' : 'bottom'">
  <dialog-header (dialogClose)="closeDialog()">
    <div>
      <span typography variant="h3">Welcome to VacayMyWay</span>
    </div>
  </dialog-header>
  <ng-container *ngIf="signUpRewardPoints">
    <div class="reward-card">
      <icon variant="reward_point" class="d-block mr-8"></icon>
      <div>
        <strong>Congratulations!</strong><br> You've earned
        <strong>10000 reward points</strong>.
        Use them on your next booking for an extra discount.
      </div>
    </div>
  </ng-container>

  <dialog-content>
    <span typography variant="h5">Verify your profile</span>
    <p typography variant="body1">
      This is a one time only process that will take just a minute to complete.
      You can skip this process now, however you will be required to complete this step during your first booking.
    </p>
  </dialog-content>

  <dialog-actions class="d-flex gap-2">
    <button class="w-100 m-20"
            button color="secondary"
            data-test="button-close"
            (click)="closeDialog()"
    >
      Skip for now
    </button>
    <button class="w-100 m-20"
            button color="primary"
            data-test="button-verify"
            (click)="openVerification()"
    >
      Verify
    </button>
  </dialog-actions>
</dialog-wrapper>
