<div class="home-offer" *ngIf="open">
  <div class="home-offer-content">
    <img loading="lazy" src="/assets/img/home/offer/offer1.png" alt="" class="home-offer-background-image"/>
    <button button variant="inline" icon="cross" color="white" class="home-offer-close" (click)="onClose()"></button>
    <div class="home-offer-text">
      <h2 typography color="inherit" class="home-offer-title">Save $100<br/>on your first<br/>booking</h2>
      <p typography color="inherit" class="home-offer-lore mt-4 mt-md-8">and earn double points for your&nbsp;next Vacay!</p>
      <a button variant="contained" color="primary" class="mt-16 mt-md-28 d-inline-block" [routerLink]="['/sign-up']">Sign up</a>
    </div>
  </div>
</div>
