import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Currency,
  VirtualAccount,
  VirtualAccountBalance,
} from '../models/virtual-account';
import { PaymentTransaction } from '../models/transaction';
import { map } from 'rxjs/operators';

const VIRTUAL_ACCOUNTS_API_BASE = '/pricing/api/v1/virtual-accounts';

@Injectable({
  providedIn: 'root',
})
export class VirtualAccountService {
  constructor(private readonly http: HttpClient) {}

  getVirtualAccounts(): Observable<VirtualAccount[]> {
    return this.http.get<VirtualAccount[]>(`${VIRTUAL_ACCOUNTS_API_BASE}`).pipe(
      map(accounts =>
        accounts.sort((a, b) => {
          // Move REWARD_POINT to the last position
          if (a.currency === b.currency) {
            return 0;
          }
          return b.currency === Currency.REWARD_POINT ? -1 : 1;
        }),
      ),
    );
  }

  getPrimaryVirtualAccount(): Observable<VirtualAccount> {
    return this.getVirtualAccount(Currency.USD);
  }

  getVirtualAccount(currency: Currency): Observable<VirtualAccount> {
    return this.getVirtualAccounts().pipe(
      map(accounts => accounts.filter(acc => acc.currency === currency)[0]),
    );
  }

  getVirtualAccountTotalBalance(
    currency: Currency,
  ): Observable<VirtualAccountBalance> {
    return this.http
      .get<{ availableBalance: number }>(
        `${VIRTUAL_ACCOUNTS_API_BASE}/${currency}`,
      )
      .pipe(
        map(balance => ({
          availableBalance: balance.availableBalance,
          currency: currency,
        })),
      );
  }

  withdrawMoney(
    currency: Currency,
    accountId: string,
    amount: number,
  ): Observable<PaymentTransaction> {
    return this.http.post<PaymentTransaction>(
      `${VIRTUAL_ACCOUNTS_API_BASE}/${currency}/withdraw`,
      {
        currency: currency,
        destinationBankAccount: accountId,
        amount: amount,
      },
    );
  }
}
