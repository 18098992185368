import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Currency,
  VirtualAccount,
} from '../../../account/models/virtual-account';
import { VirtualAccountService } from '../../../account/services/virtual-account.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-menu-account-balance',
  template: `
    <div
      typography
      variant="body2"
      color="ink"
      *ngIf="virtualAccount$ | async as virtualAccount"
    >
      <span>Balance: </span>
      <span>
        {{
          virtualAccount.availableBalance
            | currency : virtualAccount.currency : 'symbol' : '1.0-0'
        }}
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuAccountBalanceComponent {
  readonly virtualAccount$: Observable<VirtualAccount>;

  constructor(private readonly virtualAccountService: VirtualAccountService) {
    this.virtualAccount$ = this.virtualAccountService
      .getPrimaryVirtualAccount()
      .pipe(
        map(virtualAccount => ({
          ...virtualAccount,
          currency: virtualAccount.currency.toUpperCase() as Currency,
        })),
      );
  }
}
