import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'input-textarea',
  styleUrls: ['../input.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextAreaComponent,
      multi: true,
    },
  ],
  template: `
    <mat-form-field class="mb-24">
      <mat-label *ngIf="label && !hideLabel"
        >{{ label }}{{ required ? '*' : '' }}</mat-label
      >
      <textarea
        #input
        matInput
        [type]="type"
        [formControl]="control"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [rows]="rows"
        [errorStateMatcher]="errorStateMatcher"
        [cdkTextareaAutosize]="autosize"
        [class.input-textarea-autosize]="autosize"
        [class.input-label-empty]="!label || hideLabel"
        (input)="onInput($event)"
      ></textarea>
      <mat-error *ngIf="errorMessage$ | async as errorMessage">
        {{ this.errorMessage }}
      </mat-error>
      <mat-hint *ngIf="displayLength && maxlength">
        {{ textLengthSubject | async }}/{{ maxlength }} characters used
      </mat-hint>
    </mat-form-field>
  `,
})
export class InputTextAreaComponent
  extends AbstractInputComponent<string>
  implements OnInit, AfterContentInit
{
  @Input()
  type = 'text';
  @Input()
  placeholder: string;
  @Input()
  readonly: boolean;
  @Input()
  required: boolean;
  @Input()
  autosize = false;

  @Input()
  hideLabel = false;
  @Input()
  rows = 4;
  @Input()
  displayLength?: boolean;
  @Input()
  maxlength?: number;
  @Input()
  variableRows = false;
  maxRows: number;

  textLengthSubject: BehaviorSubject<number>;

  @ViewChild('input', { static: false })
  input: ElementRef<HTMLInputElement>;

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'input2');
    this.renderer.addClass(this.elementRef.nativeElement, 'input-text');
    // initiate description with only one line
    if (this.variableRows) {
      this.maxRows = this.rows;
      this.rows = 1;
    }
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
    this.textLengthSubject = new BehaviorSubject<number>(
      this.getTextLength(this.control.value),
    );
  }

  onInput(event: any) {
    this.textLengthSubject.next(this.getTextLength(event.target.value));
  }

  focus() {
    this.input.nativeElement.focus();
  }

  private getTextLength(value: any) {
    if (typeof value === 'string') {
      return value.length;
    }
    return 0;
  }
}
