import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-home-offer',
  templateUrl: './home-offer.component.html',
  styleUrls: ['./home-offer.component.scss'],
})
export class HomeOfferComponent {
  @Input()
  open: boolean;
  @Output()
  closeButton = new EventEmitter<void>();

  constructor() {}

  onClose() {
    this.closeButton.emit();
  }
}
