import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormControl,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { SubmittableFormControl } from '../../../utils/form.util';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  constructor(private hintsDisplayed: boolean) {}

  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    if (this.hintsDisplayed) {
      return false;
    }
    return !!(
      control &&
      control.invalid &&
      ((control.touched && control.dirty) ||
        (control as SubmittableFormControl).submitted)
    );
  }

  turnOnErrors() {
    this.hintsDisplayed = true;
  }

  turnOffErrors() {
    this.hintsDisplayed = false;
  }
}

const HINT_INDICATOR_DEFAULT = 'default';
const HINT_INDICATOR_INVALID = 'invalid';
const HINT_INDICATOR_VALID = 'valid';

/**
 * Password component with option of hints. These hints indicate password strength.
 */
@Component({
  selector: 'input-password',
  template: `
    <div class="d-flex gap-4 flex-column w-100">
      <mat-form-field
        [class.mb-20]="control.hasError && control.touched && control.invalid"
      >
        <!-- TODO: Replace mat-form-field with input-text once support for suffix and icon is added -->
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          [formControl]="control"
          [type]="passwordVisible ? 'text' : 'password'"
          [errorStateMatcher]="matcher"
          [maxLength]="128"
          (focusin)="hintsActive ? showHints() : false"
          (focusout)="hintsActive ? hideHints() : false"
        />
        <div *ngIf="forgotPassword || toggleButtonIsVisible()" matSuffix>
          <a
            *ngIf="forgotPassword"
            link
            variant="link2"
            color="primary"
            class="input-suffix d-inline"
            (click)="onSuffixClick()"
            >Forgot password?</a
          >
          <mat-icon
            *ngIf="toggleButtonIsVisible()"
            class="input-suffix input-suffix-icon"
            (mousedown)="togglePasswordVisibility()"
          >
            {{ passwordVisible ? 'visibility_off' : 'visibility' }}
          </mat-icon>
        </div>
        <mat-error
          *ngIf="!hintVisible && errorMessage$ | async as errorMessage"
        >
          {{ errorMessage }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="hintsActive && hintVisible" class="hints-panel">
        <div class="row">
          <div class="col-12 col-md-6">
            <hint [variant]="switchValidationHint(control.errors?.numberError)">
              <span typography variant="body2">At least 1 number</span>
            </hint>
          </div>
          <div class="col-12 col-md-6">
            <hint
              [variant]="switchValidationHint(control.errors?.lowerCaseError)"
            >
              <span typography variant="body2">At least 1 lower case</span>
            </hint>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <hint
              [variant]="switchValidationHint(control.errors?.upperCaseError)"
            >
              <span typography variant="body2">At least 1 upper case</span>
            </hint>
          </div>
          <div class="col-12 col-md-6">
            <hint [variant]="switchValidationHint(control.errors?.lengthError)">
              <span typography variant="body2">At least 8 characters</span>
            </hint>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <hint
              [variant]="
                switchValidationHint(control.errors?.specialCharacterError)
              "
            >
              <span typography variant="body2"
                >At least 1 special character</span
              >
            </hint>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../input/input.scss', 'input-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputPasswordComponent,
      multi: true,
    },
  ],
})
export class InputPasswordComponent
  extends AbstractInputComponent<string>
  implements OnInit
{
  /** Turn on/off eye icon which can show password as text */
  @Input() showToggleButton = false;
  /** Turn on/off hint indicators */
  @Input() hintsActive = false;
  @Input() forgotPassword = false;
  @Output() clickEvent = new EventEmitter();

  matcher: PasswordErrorStateMatcher;
  passwordVisible = false;
  hintVisible = false;
  iconFocus = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    injector: Injector,
  ) {
    super(injector);
    if (this.hintsActive) {
      this.matcher = new PasswordErrorStateMatcher(true);
    } else {
      this.matcher = new PasswordErrorStateMatcher(false);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'input2');
    this.renderer.addClass(this.elementRef.nativeElement, 'input-password2');
  }

  onSuffixClick() {
    this.clickEvent.emit();
  }

  toggleButtonIsVisible() {
    return this.showToggleButton && this.control.value?.length > 0;
  }

  showHints() {
    this.matcher.turnOnErrors();
    this.renderer.addClass(
      this.elementRef.nativeElement,
      'input-password-hints',
    );
    this.hintVisible = true;
  }

  hideHints() {
    if (this.iconFocus) {
      this.iconFocus = false;
      return;
    }
    // hide password, if user focused out and password was still shown as plain text
    if (this.passwordVisible) {
      this.passwordVisible = false;
    }
    this.matcher.turnOffErrors();
    this.renderer.removeClass(
      this.elementRef.nativeElement,
      'input-password-hints',
    );
    this.hintVisible = false;
  }

  /**
   * Toggle, whether the password is seen as plain text or as dots.
   * Reacts to (mousedown) event instead of (click) event, because of main
   * input (focusout) event, which toggles hideHints() function
   */
  togglePasswordVisibility() {
    this.iconFocus = true;
    this.passwordVisible = !this.passwordVisible;
  }

  switchValidationHint(validatorError: any) {
    const passwordLength = this.control.value?.length || 0;
    if (passwordLength === 0) {
      return HINT_INDICATOR_DEFAULT;
    }
    if (validatorError !== null && validatorError !== undefined) {
      return HINT_INDICATOR_INVALID;
    }
    return HINT_INDICATOR_VALID;
  }
}
