import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  PLATFORM_ID,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  AfterViewInit,
  Renderer2,
  ViewEncapsulation,
  NgZone,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormControl } from '@angular/forms';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';
import { isPlatformBrowser } from '@angular/common';
import { first } from 'rxjs';

export interface InputGuestsValue {
  guests: number;
  pets: number;
}

@Component({
  selector: 'input-guests',
  styleUrls: ['./input-guests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputGuestsComponent,
      multi: true,
    },
  ],
  template: `
    <ng-container *ngIf="!showAsPlain; else guestsForm">
      <input-text
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        (click)="onInputClick($event)"
        [label]="label"
        [formControl]="textControl"
        [placeholder]="placeholder"
        [readonly]="true"
        data-test="guest-dropdown"
        class="w-100"
      >
        <icon
          [variant]="open ? 'chevron_up' : 'chevron_down'"
          size="20"
          class="input-guests-arrow"
          *inputSuffix
        ></icon>
      </input-text>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="open"
        [cdkConnectedOverlayWidth]="overlayWidth"
        (overlayOutsideClick)="toggleOpen(); $event.stopPropagation()"
      >
        <div style="margin-top: 1px;">
          <ng-container *ngTemplateOutlet="guestsForm"></ng-container>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #guestsForm>
      <div
        [formGroup]="internalControl"
        [ngClass]="showAsPlain ? 'input-guests-plain' : 'input-guests-popup'"
        (click)="onFormClick()"
      >
        <div class="d-flex justify-content-between align-items-center">
          <span typography variant="body2" class="input-guests-label mr-16">
            Guests
          </span>
          <stepper formControlName="guests" [min]="min" [max]="max"></stepper>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-16">
          <span typography variant="body2" class="input-guests-label mr-16">
            Pets
          </span>
          <div *ngIf="!petsDisabled; else petUnfriendly">
            <stepper
              formControlName="pets"
              data-test="pet-toggle"
              [min]="0"
              [max]="maxPets"
            ></stepper>
          </div>
          <ng-template #petUnfriendly>
            <p typography variant="body2" color="primary">Not Allowed</p>
          </ng-template>
        </div>
      </div>
    </ng-template>
  `,
})
export class InputGuestsComponent
  extends AbstractInputComponent<InputGuestsValue>
  implements OnInit, AfterViewInit
{
  @Input()
  placeholder: string;
  @Input()
  readonly: boolean;
  @Input()
  required: boolean;
  @Input()
  min: number = 1;
  @Input()
  max: number = 99;
  @Input()
  maxPets: number = 99;
  @Input()
  petsDisabled = false;
  @Input()
  showAsPlain = false;

  textControl = new FormControl();
  internalControl = new FormGroup({
    guests: new FormControl(),
    pets: new FormControl(),
  });
  open: boolean;

  overlayWidth: number = undefined;

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly zone: NgZone,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'input-guests');
    this.value$.subscribe(value => {
      this.internalControl.reset({
        guests: value?.guests ?? 1,
        pets: value?.pets ?? 0,
      });
      this.setTextValue(this.value);
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.applyOverlayWidth();
    }
  }

  toggleOpen(): void {
    this.open ? this.closePopup() : this.openPopup();
  }

  openPopup(): void {
    this.open = true;
    this.applyOverlayWidth();
  }

  closePopup(): void {
    this.open = false;
    this.value = this.internalControl.getRawValue();
    this.setTextValue(this.value);
  }

  onFormClick(): void {
    if (this.showAsPlain) {
      this.value = this.internalControl.getRawValue();
    }
  }

  onInputClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.openPopup();
  }

  setTextValue(value: InputGuestsValue | null): void {
    let text;
    if (value) {
      text = `${value.guests} ${value.guests === 1 ? 'guest' : 'guests'}`;
      if (value.pets) {
        text += `, ${value.pets} ${value.pets === 1 ? 'pet' : 'pets'}`;
      }
    } else {
      text = undefined;
    }
    this.textControl.reset(text);
    this.zone.onStable
      .asObservable()
      .pipe(first())
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.applyOverlayWidth();
  }

  private applyOverlayWidth() {
    const element = this.elementRef?.nativeElement;
    if (!element) {
      return;
    }
    // 250 px is minimal width of overlay
    const overlayWidth = Math.max(element.getBoundingClientRect().width, 250);
    if (overlayWidth === this.overlayWidth) {
      return;
    }
    this.overlayWidth = overlayWidth;
    this.changeDetectorRef.detectChanges();
  }
}
