import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { AbstractComponent } from '../../../../core/components/abstract/abstract.component';
import {
  catchError,
  delay,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { isPlatformBrowser } from '@angular/common';
import { HomePropertySearchComponent } from '../home-property-search/home-property-search.component';
import { UserService } from '../../../../core/services/user.service';
import { User } from '../../../../shared/models/user.model';
import { NavbarService } from '../../../navbar/services/navbar.service';
import { ToggleService } from '../../../../core/modules/toggle/toggle.service';
import { environment } from '../../../../../environments/environment';
import Snowflakes from 'magic-snowflakes';
import {
  ContentService,
  HomePickInfo,
  PickCategory,
} from 'src/app/core/services/content.service';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { Url } from '../../../../url';
import { MenuUserPanelComponent } from '../../../navigation/menu-user-panel/menu-user-panel.component';
import { PropertyApi } from 'src/app/core/api/property.api';
import { PropertyPreview } from 'src/app/shared/models/property';

function mapPick(
  data: PropertyPreview[],
  category: PickCategory,
): HomePickInfo[] {
  return data.map(p => {
    let location = p.address?.city;
    if (p.address?.state) {
      location += `, ${p.address.state}`;
    }
    if (p.address?.country) {
      location += `, ${p.address.country.toUpperCase()}`;
    }
    const image = p.propertyImage?.imageUrl
      ? p.propertyImage.imageUrl + '_thumbnail'
      : '';
    return {
      label: p.headline,
      image,
      url: Url.PROPERTIES_(p),
      category,
      location,
      price: p.pricePerNight,
    };
  });
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent
  extends AbstractComponent
  implements OnInit, OnDestroy
{
  public readonly environment = environment;

  /** When deactivate sticky menu */
  stickyMenuThresholdMin = 100; // height from top of page in px
  /** When activate sticky menu */
  stickyMenuThresholdMax = 150; // height from top of page in px
  /** When activate sticky menu */
  offerThresholdMax = 750; // height from top of page in px
  stickyMenu = false;
  stickyMenuAnimation = false;
  largeScreen: boolean;
  offerOpen = false;
  user: User;

  /** Reference of property search visible on landing page */
  @ViewChild('search', { static: false })
  searchComponentRef: HomePropertySearchComponent;
  /** Reference of menu property search component hidden under top of screen */
  @ViewChild('searchMenu', { static: false })
  searchMenuComponentRef: HomePropertySearchComponent;
  /** Reference of property search visible on landing page */
  @ViewChild('userMenu', { static: false })
  userMenuRef: MenuUserPanelComponent;
  /** Reference of menu property search component hidden under top of screen */
  @ViewChild('userMenuSticky', { static: false })
  userMenuStickyRef: MenuUserPanelComponent;

  private snowflakes: Snowflakes;
  buildNumber = this.configurationService.getConfig('buildNumber');

  picks$ = this.content.getPicks().pipe(shareReplay(1));
  propertyPicks$: Observable<HomePickInfo[]> = isPlatformBrowser(
    this.platformId,
  )
    ? this.picks$.pipe(
        map(data =>
          data.filter(x => x.category === PickCategory.PROPERTY_TYPE),
        ),
      )
    : of([]);
  locationPicks$: Observable<HomePickInfo[]> = isPlatformBrowser(
    this.platformId,
  )
    ? this.picks$.pipe(
        map(data => data.filter(x => x.category === PickCategory.LOCATION)),
      )
    : of([]);
  vacayPicks1$: Observable<HomePickInfo[]> = isPlatformBrowser(this.platformId)
    ? this.picks$.pipe(
        map(data =>
          data.filter(
            (
              x,
            ): x is {
              id: string;
              category: PickCategory.TOP_VACAY_1;
            } => PickCategory.TOP_VACAY_1 === x.category,
          ),
        ),
        switchMap(data => this.propertyApi.getByIds(data.map(x => x.id))),
        catchError(() => of([])),
        map(y => mapPick(y, PickCategory.TOP_VACAY_1)),
        shareReplay(1),
      )
    : of([]);
  vacayPicks2$: Observable<HomePickInfo[]> = isPlatformBrowser(this.platformId)
    ? this.picks$.pipe(
        map(data =>
          data.filter(
            (
              x,
            ): x is {
              id: string;
              category: PickCategory.TOP_VACAY_2;
            } => PickCategory.TOP_VACAY_2 === x.category,
          ),
        ),
        switchMap(data => this.propertyApi.getByIds(data.map(x => x.id))),
        catchError(() => of([])),
        map(y => mapPick(y, PickCategory.TOP_VACAY_2)),
        shareReplay(1),
      )
    : of([]);

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly breakpointService: BreakpointService,
    private readonly navbarService: NavbarService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly userService: UserService,
    private readonly toggleService: ToggleService,
    private readonly content: ContentService,
    private readonly configurationService: ConfigurationService,
    private readonly propertyApi: PropertyApi,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.isPlatformBrowser()) {
      return;
    }
    // https://www.npmjs.com/package/magic-snowflakes?activeTab=readme
    this.toggleService.toggleReady$
      .pipe(
        delay(10 * 1000),
        filter(() => !!environment.enabledFeatures.snowflakes),
        this.untilDestroyed(),
      )
      .subscribe(() => {
        this.snowflakes = new Snowflakes({
          count: 5, // Default: 50
          minSize: 50,
          maxSize: 150,
        });
        this.snowflakes.start();
      });

    this.breakpointService.largeScreen$
      .pipe(this.untilDestroyed())
      .subscribe(largeScreen => {
        this.largeScreen = largeScreen;
        if (this.largeScreen && !this.stickyMenu) {
          this.searchMenuComponentRef?.onClose();
        }
      });
    this.userService
      .getCurrentUser()
      .pipe(this.untilDestroyed())
      .subscribe(user => {
        this.user = user;
        if (!user) {
          this.offerOpen = true;
        }
        this.changeDetectorRef.detectChanges();
      });
    this.updateDisplayedMenu();
    of(null)
      .pipe(this.untilDestroyed(), delay(100))
      .subscribe(() => {
        this.stickyMenuAnimation = true;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.snowflakes) {
      this.snowflakes.destroy();
    }
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (!this.isPlatformBrowser()) {
      return;
    }
    this.updateDisplayedMenu();
  }

  updateDisplayedMenu() {
    const scrollY = window.scrollY;
    let stickyMenu = this.stickyMenu;
    if (this.stickyMenu && scrollY < this.stickyMenuThresholdMin) {
      stickyMenu = false;
    } else if (!this.stickyMenu && scrollY > this.stickyMenuThresholdMax) {
      stickyMenu = true;
    }
    if (this.stickyMenu !== stickyMenu) {
      this.stickyMenu = stickyMenu;
      if (this.userMenuRef?.isOpen || this.userMenuStickyRef?.isOpen) {
        if (this.stickyMenu) {
          this.userMenuStickyRef?.openMenu();
          this.userMenuRef?.closeMenu();
        } else {
          this.userMenuStickyRef?.closeMenu();
          this.userMenuRef?.openMenu();
        }
      }
      this.changeDetectorRef.detectChanges();
      if (this.largeScreen) {
        this.searchMenuComponentRef?.onClose();
      }
    }
  }

  isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  openMobileMenu() {
    this.navbarService.openMobileMenu();
  }

  onFindYourVacay() {
    if (this.searchMenuComponentRef.open) {
      this.searchMenuComponentRef.onClose();
    } else {
      this.searchMenuComponentRef.onOpen();
      this.searchComponentRef.onClose();
    }
  }

  onWhereAreYouGoing() {
    this.searchMenuComponentRef.onWhereAreYouGoing();
  }

  onOfferClose() {
    this.offerOpen = false;
  }
}
