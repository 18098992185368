import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Property } from '../../../../shared/models/property';
import { BookingService } from '../../../../core/services/booking.service';
import { groupBy } from 'lodash-es';
import BigNumber from 'bignumber.js';
import { PropertyCalendarApi } from '../../../../core/api/property-calendar.api';

@Component({
  selector: 'app-day-cost-breakdown',
  template: `
    <span *ngIf="!property || !checkIn || !checkOut; else priceBreakdown"
      >Price per stay</span
    >
    <ng-template #priceBreakdown>
      <div class="d-flex-column" *ngFor="let priceGroup of groupedPrices">
        <div class="day-row">
          <div>
            {{ priceGroup.priceGroup | currency : 'USD' }} X
            {{ priceGroup.count }}
            {{ priceGroup.count > 1 ? 'nights' : 'night' }}
          </div>
          <div class="price-column">
            {{ priceGroup.totalPrice | currency : 'USD' }}
          </div>
        </div>
        <div>
          The rate per night is rounded. Final calculated Price Per Stay rate
          may include some additional fees from the owner or property manager.
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .day-row {
        display: flex;
        justify-content: space-between;
      }

      .price-column {
        margin-left: 32px;
        font-weight: bold;
      }
    `,
  ],
})
export class DayCostBreakdownComponent implements OnInit, AfterContentChecked {
  @Input()
  property: Property;
  @Input()
  checkIn: Date;
  @Input()
  checkOut: Date;

  // Used, so tooltip isnt recalculated every time, after content check
  lastCheckIn: Date;
  lastCheckOut: Date;
  groupedPrices: {
    priceGroup: number;
    count: number;
    totalPrice: number;
  }[];

  constructor(
    private readonly bookingService: BookingService,
    private readonly propertyCalendarService: PropertyCalendarApi,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.property && this.checkIn && this.checkOut) {
      this.lastCheckIn = this.checkIn;
      this.lastCheckOut = this.checkOut;
      this.getCostBreakdown();
    }
  }

  /** Needed for mobile version and when changing dates */
  ngAfterContentChecked() {
    if (
      this.property &&
      this.checkIn &&
      this.checkOut &&
      (this.lastCheckIn !== this.checkIn || this.lastCheckOut !== this.checkOut)
    ) {
      this.lastCheckIn = this.checkIn;
      this.lastCheckOut = this.checkOut;
      this.getCostBreakdown();
    }
  }

  getCostBreakdown() {
    this.propertyCalendarService
      .getPublicCalendar(this.property.id, this.checkIn, this.checkOut)
      .subscribe(value => {
        this.groupedPrices = [];
        // Last date is checkout -> isn't counted in total stay price.
        const days = Object.values(value.days)
          .sort((a, b) => a.date.valueOf() - b.date.valueOf())
          .slice(0, -1);
        const groups = groupBy(days.map(day => day.price));
        Object.keys(groups).forEach(key => {
          const price = parseFloat(key);
          // TODO: Quick fix for non number values, we need to rework this
          if (!isFinite(price)) {
            return;
          }
          this.groupedPrices.push({
            priceGroup: price,
            count: groups[key].length,
            totalPrice: new BigNumber(price)
              .multipliedBy(groups[key].length)
              .decimalPlaces(2)
              .toNumber(),
          });
        });
        this.cdRef.detectChanges();
      });
  }
}
