import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '@components/abstract/abstract-input.component';
import { from, Observable, switchMap } from 'rxjs';
import {
  LocationDataType,
  MapAddress,
} from '../../../shared/models/map-address';
import { debounceTime } from 'rxjs/operators';
import { GeocodingService } from '../../../core/services/geocoding.service';

export interface InputLocationValue {
  text: string;
  address: MapAddress | null;
}

interface LocationOption {
  text: string;
  line1: string;
  line2?: string;
}

@Component({
  selector: 'input-location',
  styleUrls: ['../input/input.scss', './input-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputLocationComponent,
      multi: true,
    },
  ],
  template: `
    <mat-form-field *ngIf="!showInFullscreen; else fullScreenLocation">
      <mat-label>{{ label }}{{ required ? '*' : '' }}</mat-label>
      <input
        #input
        matInput
        [formControl]="internalControl"
        [placeholder]="placeholder"
        [errorStateMatcher]="errorStateMatcher"
        [matAutocomplete]="autoComplete"
        (blur)="onBlur()"
      />

      <mat-autocomplete
        autoActiveFirstOption
        #autoComplete="matAutocomplete"
        class="autocomplete-content-width"
        [displayWith]="locationDisplayFn"
      >
        <mat-option
          *ngFor="let address of addresses$ | async"
          [value]="address"
        >
          <ng-container *ngIf="mapAddressToLocation(address) as location">
            <p typography variant="body2">
              {{ location.line1 }}
            </p>
            <p
              typography
              variant="body3"
              color="inherit"
              *ngIf="location.line2"
            >
              {{ location.line2 }}
            </p>
          </ng-container>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="errorMessage$ | async as errorMessage">
        {{ this.errorMessage }}
      </mat-error>
    </mat-form-field>
    <ng-template #fullScreenLocation>
      <div class="d-flex flex-column w-100">
        <mat-form-field>
          <mat-label>{{ label }}{{ required ? '*' : '' }}</mat-label>
          <input
            #input
            matInput
            [formControl]="internalControl"
            [placeholder]="placeholder"
            [errorStateMatcher]="errorStateMatcher"
            (blur)="onBlur()"
          />
        </mat-form-field>
        <div
          *ngFor="let address of addresses$ | async"
          class="d-flex my-8"
          (click)="selectLocation(address)"
        >
          <ng-container *ngIf="mapAddressToLocation(address) as location">
            <icon variant="location_o" color="ink" class="m-12"></icon>
            <div>
              <p typography variant="body1">{{ location.line1 }}</p>
              <p
                typography
                variant="body2"
                color="inherit"
                *ngIf="location.line2"
              >
                {{ location.line2 }}
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  `,
})
export class InputLocationComponent
  extends AbstractInputComponent<InputLocationValue>
  implements OnInit, OnDestroy
{
  @Input()
  placeholder: string;
  @Input()
  readonly: boolean;
  @Input()
  required: boolean;
  @Input()
  useFullScreen: boolean;
  @Input()
  showInFullscreen = false;

  @Input()
  locationTypes: LocationDataType[] = [
    LocationDataType.PLACE,
    LocationDataType.REGION,
    LocationDataType.COUNTRY,
    LocationDataType.LOCALITY,
  ];

  internalControl: UntypedFormControl;

  @ViewChild('input', { static: false })
  input: ElementRef<HTMLInputElement>;

  readonly locationDisplayFn = (address: MapAddress) =>
    this.mapAddressToLocation(address)?.text;
  addresses$: Observable<MapAddress[]>;

  constructor(
    injector: Injector,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly geocodingService: GeocodingService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(this.elementRef.nativeElement, 'input2');
    this.renderer.addClass(this.elementRef.nativeElement, 'input-location');
    this.internalControl = new UntypedFormControl();
    this.initAutocomplete();
    this.value$.subscribe(async value => {
      if (!value) {
        return;
      }
      if (this.isAddress(value.address)) {
        const address = value.address;
        this.internalControl.reset(address);
        this.value = {
          text: this.locationDisplayFn(address),
          address: address,
        };
      } else {
        if (value.text) {
          this.geocodingService
            .forwardGeocode(value.text, this.locationTypes)
            .subscribe(geoLocations => {
              const geoLocation =
                geoLocations?.length > 0 ? geoLocations[0] : undefined;
              this.internalControl.reset(geoLocation);
              this.value = {
                text: value.text,
                address: geoLocation,
              };
            });
        } else {
          this.internalControl.reset(undefined);
          this.value = {
            text: undefined,
            address: undefined,
          };
        }
      }
    });
    this.internalControl.valueChanges.subscribe(value => {
      if (this.isAddress(value)) {
        this.emitValueChange();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onBlur() {
    this.emitValueChange();
  }

  private emitValueChange() {
    const value = this.internalControl.value;
    if (this.isAddress(value)) {
      const address = value as MapAddress;
      this.value = {
        text: this.locationDisplayFn(address),
        address: address,
      };
    } else {
      this.value = {
        text: value?.text,
        address: undefined,
      };
    }
  }

  private initAutocomplete(): void {
    this.addresses$ = this.internalControl.valueChanges.pipe(
      debounceTime(500),
      switchMap((query: string | MapAddress) => {
        const queryText = typeof query === 'string' ? query : query?.place_name;
        if (queryText) {
          return this.geocodingService.forwardGeocode(
            queryText,
            this.locationTypes,
          );
        }
        return from([]);
      }),
    );
  }

  mapAddressToLocation(address: MapAddress): LocationOption {
    if (!address) {
      return null;
    }
    const context: {
      [key: string]: {
        id?: string;
        text?: string;
        short_code?: string;
        wikidata?: string;
      };
    } = address.context?.reduce(
      (acc, item) => ({ ...acc, [item.id.split('.')[0]]: item }),
      {},
    ) as any;
    switch (address.place_type.length) {
      case 1:
        switch (address.place_type[0]) {
          case LocationDataType.COUNTRY:
            return {
              text: address.text,
              line1: address.text,
            };
          case LocationDataType.REGION:
            if (context.country) {
              return {
                text: `${
                  address.text
                }, ${context.country.short_code.toUpperCase()}`,
                line1: `${
                  address.text
                }, ${context.country.short_code.toUpperCase()}`,
              };
            }
            return {
              text: `${address.text}`,
              line1: `${address.text}`,
            };
          case LocationDataType.PLACE:
            const line2 = [
              context.region?.text,
              context.country?.short_code.toUpperCase(),
            ]
              .filter(item => !!item)
              .join(', ');
            return {
              text: `${address.text}, ${line2}`,
              line1: address.text,
              line2: line2,
            };
        }
    }
    return {
      text: address.place_name,
      line1: address.place_name,
    };
  }

  selectLocation(address: MapAddress): void {
    this.internalControl.reset(address);
    this.value = {
      text: address.place_name,
      address: address,
    };
  }

  private isAddress(address: MapAddress): boolean {
    return typeof address?.place_name === 'string';
  }

  focus(options?: FocusOptions) {
    this.input.nativeElement.focus(options);
  }
}
